'use client';

import { useRouter } from 'next/navigation';
import { ElementType, useEffect, useMemo, useState } from 'react';

import {
    ActiveFilters,
    ActiveFiltersType,
    Cms,
    Comparator,
    Filter,
    PageHeader,
    ProductList,
    ProductListProps,
    Search,
    SortOptionType,
    Sorter,
    StickersType,
    TopFilters,
} from '@bytel/product-wall';
import { addToActiveFilters, countActiveFilters } from '@bytel/product-wall/helpers';
import { ProductInterface } from '@bytel/product-wall/types';
import {
    Button,
    ButtonType,
    Columns,
    ColumnsItem,
    Container,
    Divider,
    Icon,
    IconName,
    Modal,
    Pager,
    Pagination,
    Section,
    Spacer,
    SpacerSize,
    StickyPosition,
    Text,
    TextLevels,
    TrilogyColor,
    TypographyBold,
    VariantState,
} from '@bytel/trilogy-react-ts';
import { Item } from '@bytel/trilogy-react-ts/lib/components/autocomplete/AutoCompleteProps';

import { getCart, isPro } from '@services/cart';
import { getAccordionSeo } from '@services/cms/accordion-seo';
import { getSlider } from '@services/cms/sliders';
import { getLastSeenProductSticker } from '@services/cms/sticker';
import { getAppConfig } from '@services/config';
import { getCurrentPlan } from '@services/products';
import { publicationsService } from '@services/publications';
import { getSessionVariable, setSessionVariable } from '@services/storage';

import { getStickers } from '@actions/sticker';

import { WallConfigType, WallType } from '@app-types/config';
import { ContractType } from '@app-types/contract';
import { LegalType } from '@app-types/legals';
import { SearchParamsType } from '@app-types/page';
import { PlanDetailWithProvenanceType, PlanProvenanceType } from '@app-types/plan';
import { Product } from '@app-types/product';
import { UserType } from '@app-types/user';

import { ContractSelectionModal } from '@components/contract-selection-modal';
import { HandsFree } from '@components/hands-free';
import { Konami } from '@components/konami';
import { Legals } from '@components/legals';
import { LinkCustom } from '@components/link-custom';
import { ProductDetailsModal } from '@components/product-details-modal';
import { TriggerLogin } from '@components/trigger-login';

import { getPlanFromCart } from '@helpers/cart';
import { getClientCookies } from '@helpers/cookies-client';
import { replaceWindowUrl, setDocumentTitle } from '@helpers/document';
import { paths } from '@helpers/path';
import { formatPhoneNumber } from '@helpers/phone-number';
import { findProduct, getDisplayProducts } from '@helpers/product';
import {
    addSearchParams,
    generateHref,
    generateSubtitle,
    generateTitle,
    getActiveFilters,
    removeSearchParams,
    resetSearchParams,
} from '@helpers/seo';
import { sendPageViewTracking } from '@helpers/tracking';

import { productsSkeleton } from '@constants/skeletons';

import { getProductsAction } from '@product-wall/actions';
import { Breadcrumb, EmptyProductList, ProductRedirectModal, RedirectionOptionsType } from '@product-wall/components';
import {
    getStoredComparator,
    sendAddToComparatorTracking,
    sendFilterTracking,
    sendPageChangeTracking,
    sendPriceDetailTracking,
    sendRecommendedProductTracking,
    sendRedirectToComparatorTracking,
    sendRemoveFromComparatorTracking,
    sendResetComparatorTracking,
    sendResetFilterTracking,
    sendSearchTracking,
    sendSortTracking,
    sendToggleComparatorTracking,
    sendTopFilterTracking,
    storeComparator,
} from '@product-wall/helpers';

export type WallProps = {
    title: string;
    subtitle?: string;
    type: WallType;
    products: Product[];
    count: number;
    startPage: number;
    activeFilters?: ActiveFiltersType;
    availableSorts?: SortOptionType[];
    activeSort?: string;
    pageSize?: number;
    stickers?: ProductListProps['stickers'];
    wallConfig: WallConfigType;
    searchParams?: SearchParamsType;
    searchList: Item[];
    slider?: string;
    accordionSeo?: string;
    enableKonami?: boolean;
    enableHandsFree?: boolean;
    plan?: PlanDetailWithProvenanceType;
    autoComplete?: string;
    isRenewal?: boolean;
    user?: UserType;
    contract?: ContractType;
    trackingData?: {
        page: string;
        pageCategory: string;
        marketLine?: string;
        isB2B?: boolean;
        eCommerceStep?: string;
        variant?: Record<string, string | boolean>;
    };
    enableCompare?: boolean;
    isLoading?: boolean;
    handleLogin?: boolean;
    showPrices?: boolean;
    imageAs?: ElementType;
};

const appConfig = getAppConfig();

export function Wall({
    title,
    subtitle: _subtitle,
    type,
    products,
    count: _count,
    startPage,
    activeFilters: _activeFilters = {},
    activeSort = 'meilleures-ventes',
    pageSize = 30,
    stickers: _stickers = {},
    wallConfig,
    searchParams: _searchParams,
    slider,
    accordionSeo,
    searchList,
    enableKonami = false,
    enableHandsFree = false,
    isRenewal = false,
    user,
    contract,
    plan: _plan,
    autoComplete,
    trackingData,
    enableCompare = false,
    isLoading: _isLoading = false,
    handleLogin = true,
    showPrices = true,
    imageAs: Image,
}: Readonly<WallProps>) {
    const router = useRouter();

    const [currentPage, setCurrentPage] = useState(startPage);
    const [currentFilters, setCurrentFilters] = useState(_activeFilters);
    const [currentSort, setCurrentSort] = useState(activeSort);
    const [currentAutoComplete, setCurrentAutoComplete] = useState(autoComplete);
    const [comparedProducts, setComparedProducts] = useState<ProductInterface[]>([]);

    const [isLoading, setIsLoading] = useState(_isLoading);
    const [count, setCount] = useState(_count);
    const [sliderContent, setSliderContent] = useState<string | undefined>(slider);
    const [accordionSeoContent, setAccordionSeoContent] = useState<string | undefined>(accordionSeo);
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<Product | undefined>();
    const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
    const [productsList, setProductsList] = useState(getDisplayProducts(products, _plan, contract));
    const [isContractSelectionModalOpen, setIsContractSelectionModalOpen] = useState(false);
    const [redirectUrls, setRedirectUrls] = useState<RedirectionOptionsType | undefined>();
    const [plan, setPlan] = useState<PlanDetailWithProvenanceType | undefined>(_plan);
    const [isPricesShown, setIsPricesShown] = useState(showPrices);
    const [subtitle, setSubtitle] = useState(_subtitle);
    const [searchParams, setSearchParams] = useState<URLSearchParams>(
        _searchParams ? new URLSearchParams(_searchParams) : new URLSearchParams(),
    );
    const [isTaxExcluded, setIsTaxExcluded] = useState(false);
    const [hasToScroll, setHasToScroll] = useState(0);
    const [stickers, setStickers] = useState<ProductListProps['stickers']>(_stickers);

    const isCdc = useMemo(() => {
        return user?.user_type === 'CDC';
    }, [user]);

    const href = (name: string, value: string, active?: ActiveFiltersType): string => {
        if (name === '') {
            return wallConfig.baseUrl;
        }
        return `${wallConfig.baseUrl}/${generateHref(
            wallConfig,
            addToActiveFilters(active || currentFilters, name, value),
            searchParams,
            false,
        )}`;
    };

    const hrefAbsolute = (name: string, value: string): string => href(name, value, {});

    const setHasSelectedWallRedirection = () => {
        setRedirectUrls(undefined);
        setSessionVariable('hasSelectedWallRedirection', 'true');
    };

    const refreshCMS = async (activeFilters: ActiveFiltersType) => {
        if (type !== WallType.PHONE) {
            return;
        }

        const results = await Promise.allSettled([
            getSlider(wallConfig, activeFilters, isRenewal),
            getAccordionSeo(wallConfig, activeFilters, isRenewal),
        ]);

        setSliderContent(results[0].status === 'fulfilled' ? results[0].value : undefined);
        setAccordionSeoContent(results[1].status === 'fulfilled' ? results[1].value : undefined);
    };

    const onFilter = async (activeFilters: ActiveFiltersType, urlSearchParams?: URLSearchParams) => {
        setCurrentPage(1);
        setCurrentFilters(activeFilters);
        setIsLoading(true);
        setDocumentTitle(generateTitle(title, { activeFilters, wallConfig }));
        await updateProducts(generateHref(wallConfig, activeFilters, urlSearchParams ?? searchParams, false));
    };

    const onFilterTracking = (label: string, value: string) => {
        const hasBeenUnchecked = currentFilters[label]?.includes(value);

        sendFilterTracking(wallConfig, isRenewal, label, value, !hasBeenUnchecked);
    };

    const onTopFilterTracking = (filterLabel: string) => {
        sendTopFilterTracking(isRenewal, filterLabel);
    };

    const onSearch = async (value: string) => {
        if (value) {
            setCurrentPage(1);
            const newSearchParams = addSearchParams('nom', value, searchParams);
            setIsLoading(true);
            setCurrentAutoComplete(value);
            await updateProducts(generateHref(wallConfig, currentFilters, newSearchParams, false));

            sendSearchTracking(isRenewal);
        } else {
            const newSearchParams = removeSearchParams('nom', searchParams);
            setCurrentAutoComplete('');
            await updateProducts(generateHref(wallConfig, currentFilters, newSearchParams, false));
        }
    };

    const onChangePage = async (pager: Pager) => {
        setCurrentPage(pager.currentPage);
        const newSearchParams = addSearchParams('page', pager.currentPage.toString(), searchParams);
        setIsLoading(true);
        sendPageChangeTracking(isRenewal);
        await updateProducts(generateHref(wallConfig, currentFilters, newSearchParams, true));
    };

    const onSort = async (sort: string | undefined) => {
        let newSearchParams: URLSearchParams;
        if (sort) {
            setCurrentPage(1);
            setCurrentSort(sort);
            newSearchParams = addSearchParams('sort', sort, searchParams);

            sendSortTracking(wallConfig, isRenewal, sort);
        } else {
            newSearchParams = removeSearchParams('sort', searchParams);
        }
        setIsLoading(true);
        await updateProducts(generateHref(wallConfig, currentFilters, newSearchParams, false));
    };

    const onSelectProduct = ({ id }: ProductInterface) => {
        const product = findProduct(productsList, id);
        setSelectedProduct(product);

        sendPriceDetailTracking(isRenewal);
    };

    const onProductClick = (product: ProductInterface) => {
        const planQuery = plan?.origin === PlanProvenanceType.URL ? `?forfait=${plan.urlKey}` : '';

        if (isRenewal || getSessionVariable('hasSelectedWallRedirection')) {
            router.push(`${product.url}${planQuery}`);
        } else {
            setRedirectUrls({
                acquisition: { url: paths.phoneDetail(`${product.id}${planQuery}`) },
                renewal: { url: paths.phoneDetail(`${product.id}${planQuery}`, true) },
            });
        }
    };

    const onCompare = (product: ProductInterface) => {
        let newComparedProducts = [...comparedProducts];
        if (comparedProducts.some((p) => p.gencode === product.gencode)) {
            newComparedProducts = newComparedProducts.filter((p) => p.gencode !== product.gencode);
        } else {
            newComparedProducts.push(product);
        }
        sendAddToComparatorTracking(isRenewal);
        setComparedProducts(newComparedProducts);
        storeComparator(isRenewal, newComparedProducts);
    };

    const resetFilters = () => {
        // Remove search
        setCurrentAutoComplete('');
        const newSearchParams = resetSearchParams(searchParams, ['sort']);

        // Remove filters
        onFilter({}, newSearchParams);

        sendResetFilterTracking(isRenewal);
    };

    const updateProducts = async (
        generatedUrl?: string,
        currentPlan: PlanDetailWithProvenanceType | undefined = plan,
    ) => {
        let newSearchParams: URLSearchParams | undefined;
        let filters: ActiveFiltersType = currentFilters;
        let pageNumber: number = currentPage;
        let sort: string = currentSort;
        let newStickers: StickersType = {};

        if (generatedUrl !== undefined) {
            const [filterString, queryParams] = generatedUrl.split('?');

            newSearchParams = new URLSearchParams(queryParams);
            filters = getActiveFilters(wallConfig, (filterString ?? '').replace('filtre/', ''), newSearchParams);

            if (newSearchParams.has('page')) {
                pageNumber = parseInt(newSearchParams.get('page') as string);
            } else {
                pageNumber = 1;
                newSearchParams.set('page', '1');
            }

            if (newSearchParams.has('sort')) {
                sort = newSearchParams.get('sort') ?? '';
            }

            let url = `${wallConfig.baseUrl}/${isRenewal ? 'renouvellement/' : ''}`;
            if (generatedUrl.startsWith('?')) {
                url = url.slice(0, -1);
            }
            url += generatedUrl;

            replaceWindowUrl(url);
            setSearchParams(newSearchParams);

            const [_, stickersResult] = await Promise.all([
                refreshCMS(filters),
                type === WallType.PHONE ? getStickers(wallConfig, filters, isRenewal) : undefined,
            ]);

            if (type === WallType.PHONE) {
                newStickers = stickersResult!;

                const lastSeenSticker = Object.values(stickers ?? {}).find(
                    (sticker) => sticker.id === 'last-seen-product',
                );
                if (lastSeenSticker) {
                    newStickers['0'] = lastSeenSticker;
                }
                setStickers(newStickers);
            }

            setHasToScroll((prev) => prev + 1);
        }

        getProductsAction(type, {
            sort,
            pageNumber,
            limit: pageSize,
            filters,
            plan: currentPlan?.gencode,
            autoComplete: newSearchParams?.get('nom') ?? undefined,
            renewal: isRenewal,
            contract: contract?.id,
            stickers: Object.keys(newStickers ?? {}),
        })
            .then((result) => {
                handleUpdateProductList(getDisplayProducts(result.products, currentPlan, contract));
                setCount(result.count);
                setIsPricesShown(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const scrollToTop = () => {
        window.document.getElementById('products')?.scrollIntoView({ behavior: 'smooth' });
    };

    const filters = useMemo(() => {
        return Object.values(wallConfig.filters).sort((a, b) => {
            return a.index - b.index;
        });
    }, [wallConfig.filters]);

    const handleUpdateProductList = (products: Product[]) => {
        const partnerIds = products.map((product) => product.partnerId).filter((id) => id) as string[];
        if (getClientCookies('LesNumeriques') === 'true' && partnerIds.length > 0) {
            publicationsService.getProductsTestsPublications(partnerIds).then((response) => {
                setProductsList(
                    [...products].map((product) => {
                        const productTest = response?.find((r) => r.id.toString() === product.partnerId);
                        return {
                            ...product,
                            partner: productTest
                                ? {
                                      image: productTest.product.distinctions[0]?.image_url ?? '',
                                      imageAlt: productTest.product.distinctions[0]?.name ?? '',
                                  }
                                : undefined,
                        };
                    }),
                );
            });
        } else {
            setProductsList(products);
        }
    };

    useEffect(() => {
        if (trackingData) {
            sendPageViewTracking(isRenewal, trackingData);
        }
        handleUpdateProductList(productsList);
    }, []);

    useEffect(() => {
        if (enableCompare) {
            const newComparedProducts = getStoredComparator();
            setComparedProducts(newComparedProducts);
        }
    }, [enableCompare]);

    useEffect(() => {
        if (type === WallType.PHONE) {
            const lastseenproduct = getClientCookies('lastseenproduct');
            if (lastseenproduct) {
                getLastSeenProductSticker(lastseenproduct).then((lastSeenProductSticker) => {
                    if (lastSeenProductSticker) {
                        setStickers({ ...stickers, '0': lastSeenProductSticker });
                    }
                });
            }

            const result = getCart(isRenewal);
            if (result) {
                getPlanFromCart(result)
                    .then((planFromCart) => {
                        if (planFromCart) {
                            getCurrentPlan(searchParams, planFromCart, isRenewal, contract).then((currentPlan) => {
                                setPlan(currentPlan);
                                setSubtitle(generateSubtitle(currentPlan));
                                updateProducts(undefined, currentPlan);
                            });
                        } else {
                            throw new Error('No plan found in cart');
                        }
                    })
                    .catch(() => {
                        setIsPricesShown(true);
                    });

                setIsTaxExcluded(isPro(isRenewal));
            } else {
                setIsPricesShown(true);
            }
        }
    }, [type]);

    useEffect(() => {
        if (isRenewal) {
            const isPremiumRenewal = contract?.subscription.isPremium ?? false;
            productsList
                .filter((product) => product.recommended)
                .forEach((product) => {
                    sendRecommendedProductTracking(isRenewal, isPremiumRenewal, product);
                });
        }
    }, [productsList]);

    useEffect(() => {
        if (hasToScroll) {
            scrollToTop();
        }
    }, [hasToScroll]);

    return (
        <div>
            <PageHeader
                title={
                    isRenewal ? (
                        <>
                            Bonjour
                            {!isCdc && (
                                <>
                                    {' '}
                                    <span className='is-capitalized'>{user?.given_name?.toLocaleLowerCase()}</span>,
                                </>
                            )}
                        </>
                    ) : (
                        generateTitle(title, { wallConfig, activeFilters: currentFilters })
                    )
                }
                subtitle={
                    isRenewal && !isCdc ? (
                        <>
                            changez de téléphone pour la ligne{' '}
                            <button
                                type='button'
                                className='button-clear link'
                                aria-label='Choisir une autre ligne'
                                onClick={() => setIsContractSelectionModalOpen(true)}
                            >
                                {formatPhoneNumber(contract?.phoneNumber ?? '')}
                            </button>
                        </>
                    ) : (
                        subtitle
                    )
                }
            />
            {handleLogin && <TriggerLogin />}
            {wallConfig.topFilters && (
                <TopFilters
                    topFilters={wallConfig.topFilters}
                    onFilter={onFilter}
                    onChange={onTopFilterTracking}
                    href={hrefAbsolute}
                    activeFilters={currentFilters}
                />
            )}
            <Cms content={sliderContent} />
            <Section id='products' background={TrilogyColor.GREY_LIGHTER}>
                <Columns>
                    <aside className='column is-paddingless is-hidden-mobile is-hidden-tablet-only is-3-desktop is-fullwidth container-scroll'>
                        <Container
                            sticky={StickyPosition.TOP}
                            fullwidth
                            background={TrilogyColor.GREY_LIGHTER}
                            className='filter-scroll z-index-2 pr-1'
                        >
                            <div>
                                {/** Spacer huge avec padding pour que ça reste aligné en sticky **/}
                                <div className='padding-spacer' />
                                <Icon
                                    name={IconName.SLIDERS}
                                    content='Filtres'
                                    textClassName='is-size-3 has-text-weight-semibold'
                                />
                                <Filter
                                    id='desktop'
                                    onFilter={onFilter}
                                    availableFilters={filters}
                                    activeFilters={currentFilters}
                                    href={href}
                                    onChange={onFilterTracking}
                                    linkAs={LinkCustom}
                                    search={currentAutoComplete}
                                    resetFilters={resetFilters}
                                    onClearSearch={() => onSearch('')}
                                >
                                    <Search
                                        placeholder='Modèle, marque...'
                                        iconName={IconName.SEARCH}
                                        onSubmit={onSearch}
                                        data={searchList}
                                        value={currentAutoComplete}
                                    />
                                </Filter>
                            </div>
                        </Container>
                    </aside>
                    <ColumnsItem size={12} desktopSize={9} className='is-paddingless'>
                        <Container
                            sticky={StickyPosition.TOP}
                            fullwidth
                            background={TrilogyColor.GREY_LIGHTER}
                            className='z-index-2'
                        >
                            <Columns marginless multiline mobile verticalCentered>
                                <ColumnsItem className='is-hidden-mobile is-hidden-tablet-only is-block-desktop'>
                                    <Text typo={TypographyBold.TEXT_WEIGHT_MEDIUM} level={TextLevels.ONE} marginless>
                                        {count} {wallConfig.productType}
                                        {count > 1 ? 's' : ''}
                                    </Text>
                                </ColumnsItem>
                                {wallConfig.filters && (
                                    <ColumnsItem mobileSize={5} tabletSize={4} className='is-hidden-desktop'>
                                        <Button
                                            variant={VariantState.TERTIARY}
                                            fullwidth
                                            onClick={() => setIsFilterModalOpen(true)}
                                            iconName={IconName.SLIDERS}
                                            data-cy='open-filters'
                                        >
                                            Filtres ({count})
                                        </Button>
                                        <Modal
                                            panel
                                            closeIcon
                                            active={isFilterModalOpen}
                                            onOpen={() => setIsFilterModalOpen(true)}
                                            onClose={() => setIsFilterModalOpen(false)}
                                        >
                                            <Icon
                                                name={IconName.SLIDERS}
                                                content='Filtres'
                                                textClassName='is-size-3 has-text-weight-semibold'
                                            />
                                            <Divider />
                                            <div className='filter-scroll'>
                                                <Filter
                                                    id='mobile'
                                                    onFilter={onFilter}
                                                    onChange={onFilterTracking}
                                                    availableFilters={filters}
                                                    activeFilters={currentFilters}
                                                    resetFilters={resetFilters}
                                                    href={href}
                                                    linkAs={LinkCustom}
                                                    search={currentAutoComplete}
                                                    onClearSearch={() => onSearch('')}
                                                />
                                            </div>
                                            <div className='is-fullwidth mt-auto'>
                                                <Button
                                                    type={ButtonType.BUTTON}
                                                    variant={VariantState.PRIMARY}
                                                    fullwidth
                                                    className='filter-button'
                                                    onClick={() => setIsFilterModalOpen(false)}
                                                    data-cy='close-filters'
                                                >
                                                    Appliquer les filtres ({count}
                                                    {count > 1 ? ' résultats' : ' résultat'})
                                                </Button>
                                                <Button
                                                    type={ButtonType.BUTTON}
                                                    variant={VariantState.TERTIARY}
                                                    fullwidth
                                                    onClick={resetFilters}
                                                    className='filter-button'
                                                    data-cy='close-filters'
                                                >
                                                    Réinitialiser les filtres
                                                </Button>
                                            </div>
                                        </Modal>
                                    </ColumnsItem>
                                )}
                                {wallConfig.sorter && (
                                    <ColumnsItem mobileSize={4} tabletSize={4} desktopSize={4} fullhdSize={3}>
                                        <Sorter
                                            onSort={onSort}
                                            availableSorts={wallConfig.sorter}
                                            activeSort={currentSort}
                                        />
                                    </ColumnsItem>
                                )}
                                <ColumnsItem mobileSize={3} tabletSize={4} className='is-hidden-desktop'>
                                    <Button
                                        iconName={IconName.SEARCH}
                                        variant={VariantState.TERTIARY}
                                        fullwidth
                                        accessibilityLabel='Afficher le champs de recherche'
                                        onClick={() => setIsMobileSearchOpen(!isMobileSearchOpen)}
                                    />
                                </ColumnsItem>
                            </Columns>
                            {isMobileSearchOpen && (
                                <Columns marginless className='is-hidden-desktop'>
                                    <ColumnsItem>
                                        <Search
                                            placeholder='Modèle, marque...'
                                            iconName={IconName.SEARCH}
                                            onSubmit={onSearch}
                                            data={searchList}
                                            value={currentAutoComplete}
                                        />
                                    </ColumnsItem>
                                </Columns>
                            )}
                            {(countActiveFilters(currentFilters) > 0 || currentAutoComplete) && (
                                <Columns marginless className='is-hidden-desktop'>
                                    <ColumnsItem>
                                        <ActiveFilters
                                            onChange={onFilter}
                                            availableFilters={filters}
                                            activeFilters={currentFilters}
                                            search={currentAutoComplete}
                                            onClearSearch={() => onSearch('')}
                                        />
                                    </ColumnsItem>
                                </Columns>
                            )}
                        </Container>
                        <ProductList
                            isLoading={isLoading}
                            products={isLoading ? productsSkeleton : productsList}
                            imageAs={isLoading ? 'img' : Image}
                            stickers={stickers}
                            firstElementIndex={(currentPage - 1) * pageSize}
                            openPriceDetail={onSelectProduct}
                            linkAs={LinkCustom}
                            onClick={type === WallType.PHONE ? onProductClick : undefined}
                            showPrices={isPricesShown}
                            onCompare={enableCompare ? onCompare : undefined}
                            comparedProducts={comparedProducts}
                            disableCompare={() => comparedProducts.length >= 3}
                            isTaxExcluded={isTaxExcluded}
                            data-cy='product-list'
                            mobileSize={12}
                            tabletSize={6}
                            desktopSize={4}
                            fullhdSize={4}
                        />
                        {count <= 0 && !isLoading && <EmptyProductList resetFilters={resetFilters} />}
                        <Spacer size={SpacerSize.LARGE} />
                        {pageSize < count && (
                            <Pagination
                                onClick={onChangePage}
                                count={count}
                                pageSize={pageSize}
                                defaultPage={currentPage}
                                routerLink={LinkCustom}
                                href={(page) =>
                                    page < 1 || page > Math.ceil(count / pageSize)
                                        ? ''
                                        : `?${addSearchParams('page', page.toString(), searchParams).toString()}`
                                }
                            />
                        )}
                    </ColumnsItem>
                </Columns>
            </Section>
            {comparedProducts.length > 0 && !isFilterModalOpen && (
                <Container
                    className='z-index-6'
                    sticky={StickyPosition.BOTTOM}
                    background={TrilogyColor.GREY_LIGHTER}
                    fullwidth
                    medium
                >
                    <Comparator
                        products={comparedProducts}
                        onOpen={() => sendToggleComparatorTracking(isRenewal, true)}
                        onClose={() => sendToggleComparatorTracking(isRenewal, false)}
                        onReset={() => {
                            sendResetComparatorTracking(isRenewal);
                            storeComparator(isRenewal, []);
                            setComparedProducts([]);
                        }}
                        onProductDelete={(newComparedProducts) => {
                            sendRemoveFromComparatorTracking(isRenewal);
                            storeComparator(isRenewal, newComparedProducts);
                            setComparedProducts(newComparedProducts);
                        }}
                        compareHref={(products) =>
                            `${appConfig.comparator.url}${products.map((p) => p.gencode).join(',')}`
                        }
                        onCompare={(data) => {
                            if (data.href) {
                                sendRedirectToComparatorTracking(isRenewal);
                                router.push(data.href);
                            }
                        }}
                        defaultImage={`${appConfig.assets.cmsImages.url}/wall/lazyload-phone-default-image.svg`}
                    />
                </Container>
            )}
            <Cms content={accordionSeoContent} />
            <Section background={TrilogyColor.WHITE}>
                {enableHandsFree && (
                    <>
                        <HandsFree />
                        <Spacer size={SpacerSize.MEDIUM} />
                    </>
                )}
                <Breadcrumb wallConfig={wallConfig} activeFilters={currentFilters} />
                <Legals id='wall-legals' url={LegalType.WALL} />
            </Section>
            {(isContractSelectionModalOpen || (isRenewal && !contract)) && (
                <ContractSelectionModal
                    selected={contract?.id}
                    onClose={() => setIsContractSelectionModalOpen(false)}
                />
            )}
            {enableKonami && (
                <Konami
                    action={() => {
                        document.cookie = 'konamiEnabled=true;max-age=86400';
                        router.push('/telephones-mobiles/2002');
                    }}
                />
            )}
            {selectedProduct && (
                <ProductDetailsModal
                    currentProduct={selectedProduct}
                    selectedFunding={selectedProduct.details?.fundings[0]}
                    onClose={() => setSelectedProduct(undefined)}
                    type={type}
                    plan={plan}
                    isTaxExcluded={isTaxExcluded}
                    modalOpen={true}
                />
            )}
            {redirectUrls && <ProductRedirectModal onAction={setHasSelectedWallRedirection} options={redirectUrls} />}
        </div>
    );
}
