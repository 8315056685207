import { StickerType } from '@bytel/product-wall';

import { getAppConfig } from '@services/config';
import { cmsHttpService } from '@services/http';
import { CACHE_TAGS } from '@services/http/http';

const appConfig = getAppConfig();

export const getLastSeenProductSticker = async (
    value: string | undefined,
): Promise<Required<StickerType> | undefined> => {
    if (!value) {
        return;
    }

    return cmsHttpService
        .get<string>(`${appConfig.assets.cms.url}/banners/last-seen-product`, undefined, {
            ttl: parseInt(getAppConfig().cache.ttl.cms),
            tags: [CACHE_TAGS.CMS],
            headers: {
                'Content-Type': 'text/html',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                Pragma: 'no-cache',
                Expires: '0',
                Cookie: `lastseenproduct=${value};`,
            },
        })
        .then((res) => {
            return {
                position: 0,
                id: 'last-seen-product',
                html: res,
            };
        })
        .catch(() => undefined);
};
